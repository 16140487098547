import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "installation-info" }
const _hoisted_2 = { class: "installation-info__header" }
const _hoisted_3 = { class: "installation-info__header__status" }
const _hoisted_4 = { class: "installation-info__header__supplier-logo" }
const _hoisted_5 = ["src", "alt"]
const _hoisted_6 = { class: "installation-info__content" }
const _hoisted_7 = { class: "" }
const _hoisted_8 = { class: "installation-info__content__installation-number" }
const _hoisted_9 = { class: "installation-info__content__value" }
const _hoisted_10 = { class: "installation-info__content__value" }

import { computed } from "vue";
import { getSupplierLogo } from "@/utils/supplierlogo";

import StatusTag from "@/components/atoms/StatusTag.vue";
import { Suppliers } from "@/types/Suppliers";


export default /*@__PURE__*/_defineComponent({
  __name: 'InstallationInfo',
  props: {
    status: {},
    installationNumber: {},
    address: {},
    supplier: {}
  },
  setup(__props: any) {

const props = __props;

const supplierFullText = computed(() => {
  const suppliersText = {
    cemig: "CEMIG - Minas Gerais",
    "cpfl-paulista": "CPFL - São Paulo",
    "energisa-mt": "Energisa - Mato Grosso",
  };

  return suppliersText[props.supplier] || suppliersText.cemig;
});

const supplierLogo = computed(() => {
  return getSupplierLogo(props.supplier);
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(StatusTag, { status: _ctx.status }, null, 8, ["status"])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("img", {
          src: supplierLogo.value,
          alt: `${supplierFullText.value} logo`
        }, null, 8, _hoisted_5)
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", _hoisted_7, [
        _cache[0] || (_cache[0] = _createElementVNode("p", { class: "installation-info__content__label" }, "Instalação", -1)),
        _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.installationNumber), 1)
      ]),
      _createElementVNode("div", null, [
        _cache[1] || (_cache[1] = _createElementVNode("p", { class: "installation-info__content__label" }, "Concessionária", -1)),
        _createElementVNode("p", _hoisted_9, _toDisplayString(supplierFullText.value), 1)
      ]),
      _createElementVNode("div", null, [
        _cache[2] || (_cache[2] = _createElementVNode("p", { class: "installation-info__content__label" }, "Endereço", -1)),
        _createElementVNode("p", _hoisted_10, _toDisplayString(_ctx.address), 1)
      ])
    ])
  ]))
}
}

})