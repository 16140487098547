import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "invoices-page" }

import { computed, onBeforeMount } from "vue";

import { useUserStore, useInvoicesStore } from "@/stores";

import OverdueInvoicesList from "@/components/organisms/OverdueInvoicesList.vue";
import InvoicesList from "@/components/organisms/InvoicesList.vue";
import InvoicesNotFound from "@/components/organisms/InvoicesNotFound.vue";
import InvoicesBlockedByInstallationStatus from "@/components/organisms/InvoicesBlockedByInstallationStatus.vue";

import { orderInvoices } from "@/utils/orderInvoices";
import { handleError } from "@/utils/handleError";


export default /*@__PURE__*/_defineComponent({
  __name: 'InvoicesPage',
  setup(__props) {

const userStore = useUserStore();
const invoicesStore = useInvoicesStore();

const hasInvoices = computed(() => {
  const invoices = invoicesStore.invoices ?? [];

  return invoices.length >= 1;
});

const overdueInvoices = computed(() => {
  const orderedInvoices =
    orderInvoices(invoicesStore.invoices, "reference_month") ?? [];
  return orderedInvoices.filter(({ status }) => status === "overdue");
});

onBeforeMount(async () => {
  try {
    userStore.setPageTitle("Minhas Faturas");
    userStore.pageLoading = true;

    if (!hasInvoices.value) {
      await invoicesStore.getAllInvoices(userStore.selectedInstallation.id);
    }
  } catch (error) {
    handleError(error, userStore.setLoadingError);
  } finally {
    userStore.pageLoading = false;
  }
});

return (_ctx: any,_cache: any) => {
  return _withDirectives((_openBlock(), _createElementBlock("section", _hoisted_1, [
    (_unref(userStore).selectedInstallation.status === 'canceled')
      ? (_openBlock(), _createBlock(InvoicesBlockedByInstallationStatus, {
          key: 0,
          margin: ""
        }))
      : (!hasInvoices.value)
        ? (_openBlock(), _createBlock(InvoicesNotFound, {
            key: 1,
            margin: ""
          }))
        : (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
            (overdueInvoices.value.length >= 1)
              ? (_openBlock(), _createBlock(OverdueInvoicesList, {
                  key: 0,
                  overdueInvoices: overdueInvoices.value
                }, null, 8, ["overdueInvoices"]))
              : _createCommentVNode("", true),
            _createVNode(InvoicesList, {
              invoices: _unref(invoicesStore).invoices
            }, null, 8, ["invoices"])
          ], 64))
  ], 512)), [
    [_vShow, !_unref(userStore).pageLoading]
  ])
}
}

})