<script setup lang="ts">
import { computed, onBeforeMount, ref } from "vue";
import {
  MeIcon,
  MeTextField,
  MeSelect,
  MeButton,
} from "@/libs/metha-components";
import { Form } from "vee-validate";

import { useUserStore } from "@/stores";
import { formatCPF, formatDate } from "@/utils/format";
import getDateFromBirthdate from "@/utils/getDateFromFormattedString";
import { clearObjectEmptyFields } from "@/utils/clearObjectEmptyFields";
import { UpdateUserObject } from "@/types/UserTypes";
import ContactUpdateModal from "@/components/molecules/ContactUpdateModal.vue";

const userStore = useUserStore();

const email = computed(() => {
  return userStore.email;
});
const phone = computed(() => {
  return userStore.phone;
});
const birthdate = ref();
const rg = ref(userStore.rg);
const occupation = ref(userStore.occupation);
const maritalStatus = ref(userStore.maritalStatus);
const showContactUpdateModal = ref(false);
const typeContactUpdateModal = ref("");
const titleContactUpdateModal = ref("");
const textContactUpdateModal = ref("");

function hasAttributedValue(field: string) {
  return typeof field === "string"
    ? field?.trim().length > 0 || false
    : !!field;
}

async function updateUser() {
  let payload = {
    birthdate: getDateFromBirthdate(birthdate.value),
    rg: rg.value,
    occupation: occupation.value,
    civil_status: maritalStatus.value,
  } as UpdateUserObject;

  payload = clearObjectEmptyFields(payload);

  await userStore.updateUser({ ...payload });
}

function setContactUpdateModal(type: string) {
  typeContactUpdateModal.value = type;
  showContactUpdateModal.value = true;
}

function closeModal() {
  showContactUpdateModal.value = false;
  titleContactUpdateModal.value = "";
  textContactUpdateModal.value = "";
}

onBeforeMount(async () => {
  userStore.setPageTitle("Minha Conta");
  birthdate.value = formatDate(userStore.birthdate);
});
</script>

<template>
  <section class="adc__invoices__section my-account">
    <ContactUpdateModal
      v-if="showContactUpdateModal"
      :type="typeContactUpdateModal"
      closeButtonText="Continuar"
      :closeModal="closeModal"
    />
    <div class="my-account__info">
      <div class="my-account__info__icon">
        <MeIcon>nm-profile</MeIcon>
      </div>
      <h2 class="my-account__info__name">{{ userStore.name }}</h2>
      <p class="my-account__info__cpf">CPF: {{ formatCPF(userStore.cpf) }}</p>
    </div>
    <Form class="my-account__form">
      <h3 class="my-account__form__title">Informações de contato</h3>
      <div class="my-account__form__content">
        <div
          class="my-account__form__content__siding"
          @click="setContactUpdateModal('email')"
        ></div>
        <MeTextField
          label="E-mail"
          name="email"
          v-model="email"
          rules="email|required"
        />
        <div class="my-account__form__content__action">Alterar E-mail</div>
        <div class="my-account__form__content__actionArrow">
          <MeIcon>nm-arrow-right</MeIcon>
        </div>
      </div>
      <div class="my-account__form__content">
        <div
          class="my-account__form__content__siding"
          @click="setContactUpdateModal('phone')"
        ></div>
        <MeTextField
          label="Celular"
          name="phone"
          v-model="phone"
          v-maska="'(##) #####-####'"
          rules="required|phone"
        />
        <div class="my-account__form__content__action">Alterar Celular</div>
        <div class="my-account__form__content__actionArrow">
          <MeIcon>nm-arrow-right</MeIcon>
        </div>
      </div>
    </Form>
    <Form
      @submit="updateUser"
      v-slot="{ errors, isSubmitting }"
      class="my-account__form"
    >
      <h3 class="my-account__form__title">Informações pessoais</h3>
      <MeTextField
        label="Data Nascimento"
        name="birthdate"
        v-model="birthdate"
        v-maska="'##/##/####'"
        :rules="`${
          hasAttributedValue(userStore.birthdate)
            ? 'required|birthdate|isAdult'
            : ''
        }`"
      />
      <MeTextField
        label="RG/Documento"
        name="rg"
        v-model="rg"
        :rules="{ max: 15, required: hasAttributedValue(userStore.rg) }"
      />
      <MeTextField
        label="Profissão"
        name="occupation"
        v-model="occupation"
        :rules="{ min: 3, required: hasAttributedValue(userStore.occupation) }"
      />
      <MeSelect
        label="Estado Civil"
        name="maritalStatus"
        v-model="maritalStatus"
        :rules="{ required: hasAttributedValue(userStore.maritalStatus) }"
        :items="[
          'Solteiro(a)',
          'Casado(a)',
          'Separado(a)',
          'Divorciado(a)',
          'Viúvo(a)',
        ]"
        class="my-account__form__select"
      />
      <MeButton
        type="submit"
        class="my-account__form__button"
        size="large"
        :disabled="!!Object.keys(errors).length || isSubmitting"
        block
      >
        Salvar alterações
      </MeButton>
    </Form>
  </section>
</template>

<style lang="scss" scoped>
.my-account {
  margin: 0 auto 2rem;
  padding: 2rem 0.75rem;

  @media (min-width: 993px) {
    padding: 3rem 1.5rem;
  }

  &__info {
    text-align: center;

    &__icon {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 100%;
      margin: 0 auto;

      color: #fff;
      background: #000;

      height: auto;
      aspect-ratio: 1 / 1;
      width: 54px;
      font-size: 1.875rem;

      @media (min-width: 993px) {
        font-size: 2.25rem;
        width: 80px;
      }
    }

    &__name {
      margin: 1rem 0 0.5rem;
      font-weight: 700;
      font-size: 1.625rem;
      line-height: 2.25rem;
      text-transform: capitalize;

      @media (min-width: 993px) {
        margin: 1rem 0 0.75rem;
        font-size: 1.875rem;
      }
    }

    &__cpf {
      color: #6c757d;
      font-weight: 600;
      font-size: 1.25rem;
      line-height: 1.5rem;
    }
  }

  &__form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: 0 auto;
    padding-top: 2.5rem;
    gap: 0.75rem;

    @media (min-width: 993px) {
      max-width: 580px;
    }

    &__select {
      width: 100%;
    }

    &__button {
      margin-top: 0.75rem;

      @media (min-width: 993px) {
        margin-top: 1.5rem;
      }
    }

    &__title {
      line-height: 24px;
      font-weight: 500;
      font-size: 20px;
      color: #000;
      width: 100%;
    }

    &__content {
      display: flex;
      position: relative;
      width: 100%;

      &__siding {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 5;
        cursor: pointer;
      }

      &__actionArrow {
        position: absolute;
        right: 0px;
        top: 13px;
        @media (min-width: 993px) {
          visibility: hidden;
        }
        @media (max-width: 993px) {
          visibility: none;
        }
      }

      &__action {
        position: absolute;
        right: 0px;
        top: -3px;
        background-color: #fff;
        border-radius: 100px;
        border-width: 1px;
        border-color: #ffcc12;
        width: 154px;
        height: 40px;
        display: flex;
        border-style: solid;
        justify-content: center;
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        padding-top: 7px;
        cursor: pointer;

        @media (max-width: 993px) {
          visibility: hidden;
        }
        @media (min-width: 993px) {
          visibility: none;
        }
      }
    }
  }
}
</style>
