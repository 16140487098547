import { defineStore } from "pinia";
import { useSessionStorage } from "@vueuse/core";

import api from "@/api";
import { useAuthStore, useUserStore } from "@/stores";

import { FlatInstallation } from "@/types/InstallationTypes";
import { Suppliers } from "@/types/Suppliers";

import { handleError } from "@/utils/handleError";

const initialCredentialsState = () => ({
  isOpen: false,
  installationLogin: undefined as string | undefined,
  step: "",
  loading: false,
  success: false,
  loginError: "",
  updatedCredentialsInstallations: [] as FlatInstallation[],
});

export const useCredentialsStore = defineStore("credentials", {
  state: () =>
    useSessionStorage(
      "@metha-energia/adc-credentials",
      initialCredentialsState()
    ),
  actions: {
    async clear() {
      Object.assign(this, initialCredentialsState());
    },
    resetLogin() {
      Object.assign(this, {
        step: "",
        loading: false,
        success: false,
        loginError: "",
        updatedCredentialsInstallations: [],
      });
    },
    clearUpdatedCredentialsInstallations() {
      this.updatedCredentialsInstallations = [];
    },
    openCredentialsModal() {
      this.isOpen = true;
    },
    setInstallationLogin(installation: FlatInstallation) {
      if (installation.legal_representative) {
        this.installationLogin = installation.legal_representative.cnpj.replace(
          /[^\d]+/g,
          ""
        );
      } else {
        this.installationLogin = undefined;
      }
    },
    closeCredentialsModal() {
      this.isOpen = false;
    },
    async updateUserCredentials(
      login: string,
      password: string,
      supplier: Suppliers
    ) {
      const authStore = useAuthStore();
      const credentialsStore = useCredentialsStore();

      try {
        credentialsStore.loading = true;

        const token = await authStore.getToken(`updateCredentials${supplier}`);
        const auth = authStore.authToken;

        const { data } = await api.external.updateCredentials(
          { [supplier]: { login, password } },
          {},
          {
            headers: {
              "g-recaptcha-response": token,
              Authorization: auth,
            },
          }
        );

        return data;
      } catch (error) {
        handleError(
          error,
          (value: string) => {
            credentialsStore.loginError = value;
          },
          {
            504: "Estamos com uma dificuldade para estabelecer a conexão com a CEMIG. Tente novamente por favor",
            default:
              "Desculpe, não foi possível concluir essa ação. Tente novamente",
          }
        );
        credentialsStore.step = "";
      }
    },
    updateInstallationsCredentialsStatus(data: { id: string | number }[]) {
      const modifiedIds = data.map(({ id }) => id);

      const userStore = useUserStore();
      const modifiedInstallations: FlatInstallation[] = [];

      const updatedInstallations = userStore.installations.map(
        (installation: FlatInstallation) => {
          if (modifiedIds.includes(installation.id)) {
            modifiedInstallations.push(installation);
            return {
              ...installation,
              pending_credentials: false,
            };
          }

          return installation;
        }
      );

      userStore.setInstallations(updatedInstallations);
      userStore.setOperationalInstallations(updatedInstallations);

      this.updatedCredentialsInstallations = modifiedInstallations;
    },
  },
});
