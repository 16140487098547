import { AxiosRequestConfig } from "axios";
import request from "./request";
import { Query, VerifyDTO } from "./types";

export async function getUser(
  data: { cpf: string },
  params: Query = {},
  config: AxiosRequestConfig = {}
) {
  return request({
    method: "GET",
    url: `/register/${data.cpf}`,
    data,
    params,
    ...config,
  });
}

export async function updateUser(
  data: any,
  params: Query = {},
  config: AxiosRequestConfig = {}
) {
  return request({
    method: "PATCH",
    url: "/register",
    data,
    params,
    ...config,
  });
}

export async function updateContactUser(
  data: any,
  params: Query = {},
  config: AxiosRequestConfig = {}
) {
  return request({
    method: "PATCH",
    url: "/client-area/update-contact",
    data,
    params,
    ...config,
  });
}

export async function updateContactSendOtpCode(
  data: { phone?: string; email?: string; cpf: string },
  params: Query = {},
  config: AxiosRequestConfig = {}
) {
  return request({
    method: "POST",
    url: "/client-area/update-contact-send-otp-code",
    data,
    params,
    ...config,
  });
}
