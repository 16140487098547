<script setup lang="ts">
import { defineProps, computed, watch, ref, onMounted } from "vue";
import { Form } from "vee-validate";

import {
  MeTextField,
  MeButton,
  MeIcon,
  MeModal,
  useBreakpoints,
} from "@/libs/metha-components";

import { useCredentialsStore, useLoginStore, useUserStore } from "@/stores";

import CaSpinner from "@/components/atoms/CaSpinner.vue";
import SuccessInstallationCard from "@/components/atoms/InstallationTile.vue";

const props = defineProps({
  submit: {
    type: Function,
    required: true,
  },
  createAccount: {
    type: Function,
    required: true,
  },
  forgotPasswordLink: {
    type: String,
    required: true,
  },
  redefinePassword: {
    type: Function,
    required: true,
  },
  closeModal: {
    type: Function,
    required: true,
  },
});

const { mdAndDown } = useBreakpoints();
const userStore = useUserStore();
const loginStore = useLoginStore();
const credentialsStore = useCredentialsStore();

const userLogin = ref("");
const userPassword = ref("");
const confirmUserPassword = ref("");
const openTutorialModal = ref(false);
const successFeedbackContent = ref();

const userEmail = computed(() => {
  if (loginStore.email.trim() !== "") return loginStore.email;

  return userStore.email;
});

const supplier = computed(() => "cemig".toUpperCase());

const hasExternalError = computed(() =>
  credentialsStore.loginError ? " " : undefined
);

function changeCredentialsStep(newStatus: string) {
  userLogin.value = loginStore.cpf ?? "";
  userPassword.value = "";
  credentialsStore.step = newStatus;
}

function registerNewAccount() {
  if (!userPassword.value.trim() || !confirmUserPassword.value.trim()) {
    credentialsStore.loginError = "Preencha todos os campos";
    return;
  }

  return props.createAccount(
    userEmail.value,
    userPassword.value,
    confirmUserPassword.value
  );
}

function login() {
  if (!userLogin.value.trim() || !userPassword.value.trim()) {
    credentialsStore.loginError = "Preencha todos os campos";
    return;
  }

  props.submit(userLogin.value, userPassword.value);
}

watch(
  () => ({
    login: userLogin.value,
    password: userPassword.value,
  }),
  () => {
    credentialsStore.loginError = "";
  }
);

onMounted(() => {
  credentialsStore.resetLogin();

  userLogin.value = (
    credentialsStore.installationLogin ?? userStore.cpf
  ).replace(/[^\d]+/g, "");
});
</script>

<template>
  <div class="cemig-installation-credentials">
    <!-- Default screen -->
    <Form @submit="login" v-if="credentialsStore.step === ''">
      <p class="cemig-installation-credentials__paragraph">
        Faça o login com as credenciais usadas para acessar o site da
        {{ supplier }}.
      </p>
      <MeTextField
        v-model="userLogin"
        label="CPF/CNPJ do usuário"
        v-maska="['###.###.###-##', '##.###.###/####-##']"
        rules="required"
        disabled
      />
      <MeTextField
        v-model="userPassword"
        label="Senha"
        rules="required"
        type="password"
      />
      <p
        class="cemig-installation-credentials--error-message"
        v-if="credentialsStore.loginError.trim() !== ''"
      >
        {{ credentialsStore.loginError }}
      </p>
      <div class="cemig-installation-credentials__buttons-container">
        <MeButton
          block
          :disabled="
            credentialsStore.loading ||
            !!(
              credentialsStore.loginError &&
              credentialsStore.loginError.length > 0
            )
          "
        >
          Acessar
        </MeButton>
        <p @click="changeCredentialsStep('registering')" class="me--link">
          Não tenho um acesso {{ supplier }}
        </p>
        <a :href="forgotPasswordLink" target="_blank" class="me--link">
          Esqueci minha senha
        </a>
      </div>
      <p class="cemig-installation-credentials__safety-badge">
        <MeIcon>shield</MeIcon>
        Ambiente 100% seguro
      </p>
    </Form>

    <div v-else-if="credentialsStore.step === 'loginLoading'">
      <div class="cemig-installation-credentials__login-loading">
        <img
          src="@/assets/gifs/credentials-loading.gif"
          preload
          loading="eager"
        />
      </div>
    </div>

    <div v-else-if="credentialsStore.step === 'loading'">
      <div class="cemig-installation-credentials__loading">
        <CaSpinner />
      </div>
    </div>

    <div v-else-if="credentialsStore.step === 'success'">
      <div
        ref="successFeedbackContent"
        class="cemig-installation-credentials__success"
      >
        <div>
          <img
            src="@/assets/gifs/spinner-check.gif"
            alt=""
            width="80"
            height="80"
            class="cemig-installation-credentials__success__icon"
          />
          <p class="cemig-installation-credentials__success__title">
            Credenciais atualizadas com sucesso
          </p>
          <p class="cemig-installation-credentials__success__text">
            Estas são as instalações associadas à sua conta CEMIG que tiveram as
            credenciais atualizadas:
          </p>
        </div>
        <div class="cemig-installation-credentials__success__cards-container">
          <SuccessInstallationCard
            v-for="(
              installation, index
            ) in credentialsStore.updatedCredentialsInstallations"
            :key="`cemig-installation-credentials-success-item-${index}`"
            :installation="installation"
          />
        </div>
        <div>
          <MeButton variant="outline" @click="() => closeModal()"
            >Fechar</MeButton
          >
        </div>
      </div>
    </div>

    <div v-else-if="credentialsStore.step === 'registering'">
      <p class="cemig-installation-credentials__paragraph">
        Sem problemas! Ajudaremos você a criar uma conta. É rápido, basta
        definir sua senha de acesso
      </p>
      <MeTextField v-model="userEmail" label="" disabled />
      <MeTextField
        v-model="userPassword"
        label="Digite uma senha"
        rules="required|min:6"
        type="password"
        :error="hasExternalError"
      />
      <MeTextField
        v-model="confirmUserPassword"
        label="Confirme a senha"
        rules="required"
        type="password"
        :error="hasExternalError"
      />
      <p
        class="cemig-installation-credentials--error-message"
        v-if="credentialsStore.loginError.trim() !== ''"
      >
        {{ credentialsStore.loginError }}
      </p>
      <div class="cemig-installation-credentials__buttons-container">
        <MeButton
          @click="registerNewAccount"
          :disabled="credentialsStore.loading"
          block
        >
          Criar conta
        </MeButton>
        <p
          @click="changeCredentialsStep('')"
          class="cemig-installation-credentials__button me--link"
        >
          Voltar
        </p>
      </div>
    </div>

    <div v-else-if="credentialsStore.step === 'hasAccount'">
      <p class="cemig-installation-credentials__paragraph">
        Você já possui uma conta no seu CPF e/ou email. Você pode solicitar para
        enviar um email para redefinir a sua senha.
      </p>
      <div class="cemig-installation-credentials__buttons-container">
        <MeButton
          @click="() => redefinePassword()"
          :disabled="credentialsStore.loading"
          type="button"
          variant="outline"
          block
        >
          Redefinir senha
        </MeButton>
        <MeButton type="button" @click="changeCredentialsStep('')" block>
          Voltar
        </MeButton>
      </div>
    </div>

    <div v-else-if="credentialsStore.step === 'createdAccount'">
      <p class="cemig-installation-credentials__paragraph">
        Caro usuário, enviamos um e-mail de confirmação de cadastro. Favor
        verificar e seguir as instruções do vídeo abaixo.
      </p>
      <div
        @click="openTutorialModal = true"
        class="cemig-installation-credentials__access__video"
      >
        <img
          src="@/assets/images/video-tutorials/tutorial-conta-criada-mobile.png"
          alt="Thumbnail do vídeo"
          class="cemig-installation-credentials__access__video-thumbnail"
          v-if="mdAndDown"
        />
        <img
          src="@/assets/images/video-tutorials/tutorial-conta-criada-desktop.jpg"
          alt="Thumbnail do vídeo"
          class="cemig-installation-credentials__access__video-thumbnail"
          v-else
        />
        <img
          src="@/assets/images/video-tutorials/video-preview-button.png"
          alt="Botão para iniciar o vídeo"
          class="cemig-installation-credentials__access__video-button"
        />
      </div>
      <MeButton type="button" @click="changeCredentialsStep('')" block>
        Voltar
      </MeButton>

      <MeModal v-model="openTutorialModal">
        <div class="cemig-installation-credentials__access__modal-container">
          <iframe
            src="https://www.youtube.com/embed/WF197OUATfw?autoplay=1"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
            autoplay
            class="cemig-installation-credentials__access__modal-video"
          ></iframe>
          <div
            @click="openTutorialModal = false"
            class="cemig-installation-credentials__access__modal-close-button"
          >
            <span><MeIcon>x</MeIcon></span>
          </div>
        </div>
      </MeModal>
    </div>

    <div v-else-if="credentialsStore.step === 'forgotPasswordSent'">
      <p class="mt-6 cemig-installation-credentials__subtitle">
        Enviamos um link para o seu email cadastrado
      </p>
      <p class="cemig-installation-credentials__paragraph">
        Se o link não chegar à sua caixa de entrada em minutos, verifique a
        pasta de spam ou
        <a :href="forgotPasswordLink" target="_blank">clique aqui</a> para
        recuperar a sua senha pelo site da {{ supplier }}.
      </p>
      <div class="cemig-installation-credentials__buttons-container">
        <MeButton type="button" @click="changeCredentialsStep('')" block>
          Voltar
        </MeButton>
      </div>
    </div>

    <!-- Error messages -->
    <div v-else>
      <p class="cemig-installation-credentials__paragraph">
        {{ credentialsStore.loginError }}
      </p>
      <MeButton type="button" @click="changeCredentialsStep('')" block>
        Voltar
      </MeButton>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.cemig-installation-credentials {
  height: 100%;

  &__safety-badge {
    background-color: #e9ecef;
    padding: 0.75rem;
    padding-left: 0.25rem;
    width: fit-content;
    margin: 1.5rem auto 0;

    i {
      vertical-align: super;
      font-size: 1.5rem;
    }
  }

  &__subtitle {
    font-size: 20px;
    font-weight: 700;
    line-height: 1.25;
    text-align: center;
    max-width: 280px;
    margin: 0 auto;
  }

  &__paragraph {
    max-width: 370px;
    line-height: 1.5em;
    margin: 0 auto;
    padding: 1.5rem 0 0.75rem;
    text-align: center;

    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5em;

    &--error {
      font-size: 1.125rem;
    }
  }

  &__buttons-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;

    padding-top: 1rem;
  }

  &__button {
    width: fit-content;
    margin: 0 auto;
  }

  &__login-loading,
  &__loading {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    border-radius: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 100%;
      max-width: 470px;
    }
  }

  &--error-message {
    color: var(--pallete-danger);
  }

  &__success {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    &__icon {
      width: 80px;
      height: 80px;
      object-fit: contain;
      object-position: center;
      margin: 0 auto;
    }

    &__title {
      font-weight: 700;
      text-align: center;
      font-size: 1.125rem;
      line-height: 1.6875rem;

      @media (min-width: 993px) {
        font-size: 1.5rem;
        line-height: 2.25rem;
      }
    }

    &__text {
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.25rem;
      text-align: center;
    }

    &__cards-container {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      max-height: 300px;
      overflow-y: auto;
    }
  }

  &__access {
    &__video {
      background: #000;
      cursor: pointer;
      display: flex;
      height: fit-content;
      margin: 1rem auto 1.5rem;
      position: relative;
      width: fit-content;
      border-radius: 0.25rem;
      box-shadow: inset 0 0 0 -1px black;
      border: 0.5px solid black;
      overflow: hidden;

      &-thumbnail {
        height: auto;
        margin: 0 auto;
        max-width: 370px;
        opacity: 0.8;
        transition: 0.5s;
        width: 100%;
      }

      &-button {
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
      }

      &:hover {
        .cemig-installation-credentials__access__video-thumbnail {
          opacity: 0.5;
        }
      }
    }

    &__modal {
      &-container {
        position: relative;
        display: flex;
      }

      &-close-button {
        color: black;
        cursor: pointer;
        padding: 5px;
        position: absolute;
        right: 2px;
        top: 5px;

        @media (min-width: 993px) {
          right: 10px;
          color: white;
        }
      }

      &-video {
        background: black;
        width: 80vw;
        max-width: 325px;
        aspect-ratio: 9 / 16;

        @media (min-width: 993px) {
          width: 90vw;
          max-width: 1280px;
          aspect-ratio: 16 / 9;
        }
      }
    }

    &__exit-modal {
      max-width: 700px;
      overflow-x: hidden;
      position: relative;

      &__placeholder {
        width: 1000px;
      }

      &__button {
        max-width: 150px;
      }

      &__close-button {
        filter: brightness(0.4);
        cursor: pointer;
        padding: 5px;
        position: absolute;
        right: 10px;
        top: 5px;
      }

      &__icon {
        font-size: 6rem;
        padding: 1.75rem 1rem;
        border-radius: 50%;
        background-color: #ffcc1233;
        color: var(--pallete-primary);
        width: fit-content;
        margin: 0 auto;

        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
</style>
