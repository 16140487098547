import { markRaw } from "vue";
import { createPinia } from "pinia";
import router from "@/router";
import { Router } from "vue-router";

declare module "pinia" {
  export interface PiniaCustomProperties {
    router: Router;
  }
}

const pinia = createPinia();

pinia.use(({ store }) => {
  store.router = markRaw(router);
});

export default pinia;

import { useAuthStore } from "./auth";
import { useContractStore } from "./contract";
import { useCredentialsStore } from "./credentials";
import { useFaqStore } from "./faq";
import { useInvoicesStore } from "./invoices";
import { useLoginStore } from "./login";
import { usePersistentAuthStore } from "./persistentAuth";
import { useUserStore } from "./user";
import { useWarningStore } from "./warning";

export {
  useAuthStore,
  useContractStore,
  useCredentialsStore,
  useFaqStore,
  useInvoicesStore,
  useLoginStore,
  usePersistentAuthStore,
  useUserStore,
  useWarningStore,
};
