<script setup lang="ts">
import { computed } from "vue";

import api from "@/api";
import { useCredentialsStore, useUserStore } from "@/stores";

import { handleError } from "@/utils/handleError";

import CemigComponent from "@/components/molecules/MethaCredentials/CemigInstallationCredentials.vue";
import DefaultCredentialsLayout from "@/components/molecules/MethaCredentials/DefaultCredentialsLayout.vue";

const userStore = useUserStore();
const credentialsStore = useCredentialsStore();

const hideSupplierInfo = computed(() => {
  return ["success"].includes(credentialsStore.step);
});

const isDispatchingRequest = computed(() => {
  return ["loading", "loginLoading"].includes(credentialsStore.step);
});

function closeModal() {
  if (isDispatchingRequest.value) return;

  if (credentialsStore.step === "success")
    credentialsStore.clearUpdatedCredentialsInstallations();

  credentialsStore.closeCredentialsModal();
}

async function registerCemigAccount(payload: {
  email: string;
  password: string;
  name: string;
  phone: string;
  document: string;
}) {
  try {
    await api.external.registerCemigAccount(payload);
    return { error: null };
  } catch (err) {
    let error = "";

    handleError(err, (errMessage: string) => {
      error = errMessage;
    });

    return { error };
  }
}

async function resetPassword(payload: { login: string }) {
  try {
    await api.external.resetCemigPassword(payload);
    return { error: null };
  } catch (err) {
    let error = "";

    handleError(err, (errMessage: string) => {
      error = errMessage;
    });

    return { error };
  }
}

async function updateCredentials(login: string, password: string) {
  if (credentialsStore.loading) return;
  credentialsStore.loading = true;

  try {
    credentialsStore.loginError = "";
    credentialsStore.step = "loginLoading";

    const data = await credentialsStore.updateUserCredentials(
      login,
      password,
      "cemig"
    );

    if (credentialsStore.loginError && credentialsStore.loginError.length > 0) {
      credentialsStore.loading = false;
      return;
    }

    credentialsStore.updateInstallationsCredentialsStatus(data);

    credentialsStore.step = "success";
  } catch (error) {
    handleError(error, (err: string) => {
      credentialsStore.loginError = err;
    });
  } finally {
    credentialsStore.loading = false;
  }
}

async function sendResetPasswordEmail(message: string) {
  if (credentialsStore.step === "loading") return;

  credentialsStore.step = "loading";

  credentialsStore.loginError = message;
  const { error } = await resetPassword({ login: userStore.cpf });

  if (error) {
    // Reativar o tratamento de erro retornado quando ele estiver funcionando melhor (com mensagens de erro mais 'user-fliendly')
    // credentialsStore.loginError = error;
    credentialsStore.loginError =
      "Não foi possível enviar o e-mail para sua redefinição de senha. Tente novamente por favor.";
  }

  credentialsStore.step = "error";
}

async function createAccount(
  email: string,
  password: string,
  confirmPassword: string
) {
  if (password !== confirmPassword) {
    credentialsStore.loginError = "As senhas precisam ser iguais";
    return;
  }

  if (credentialsStore.step === "loading") return;

  credentialsStore.step = "loading";

  const name = userStore.name;
  const normalizedPhone = userStore.phone.replace(/\D/gm, "");
  const document = userStore.cpf.replace(/\D/gm, "");

  const { error } = await registerCemigAccount({
    email,
    password,
    name,
    phone: normalizedPhone,
    document,
  });

  if (error) {
    // Reativar o tratamento de erro retornado quando ele estiver funcionando melhor (com mensagens de erro mais 'user-fliendly')
    // credentialsStore.loginError = error;
    credentialsStore.loginError =
      "Não foi possível cadastrar sua conta no momento. Tente novamente mais tarde por favor.";
    credentialsStore.step = "error";
    return;
  }

  credentialsStore.step = "";
}

async function redefinePassword() {
  await sendResetPasswordEmail(
    "O link para redefinição de senha foi enviado para para o e-mail cadastrado."
  );
}
</script>

<template>
  <div class="metha-credentials">
    <div class="metha-credentials__modal">
      <div class="metha-credentials__modal__close-button" @click="closeModal">
        <img src="@/assets/icons/x.svg" width="10" height="10" />
      </div>
      <DefaultCredentialsLayout
        supplier="cemig"
        :hideSupplierInfo="hideSupplierInfo"
      >
        <CemigComponent
          :submit="updateCredentials"
          :createAccount="createAccount"
          forgotPasswordLink="https://www.atendimento.cemig.com.br/portal/auth/password/recovery"
          :redefinePassword="redefinePassword"
          :closeModal="closeModal"
        />
      </DefaultCredentialsLayout>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.metha-credentials {
  z-index: 7;
  background-color: rgba($color: #000000, $alpha: 0.2);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  &__modal {
    position: relative;
    background-color: #fff;
    border-radius: 1.5rem;
    padding: 1.5rem 1.5rem 2rem 1.5rem;
    text-align: center;
    min-height: 400px;
    max-height: 90vh;
    overflow-y: auto;

    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;

    @media (min-width: 993px) {
      min-height: 355px;
    }

    &__close-button {
      position: absolute;
      top: 15px;
      right: 15px;

      width: 22px;
      height: 22px;

      background-color: #f2f2f2;
      border-radius: 100%;
      cursor: pointer;

      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 10px;
        aspect-ratio: 1;
        object-fit: contain;
        object-position: center;
      }
    }
  }
}
</style>
