import { AxiosRequestConfig } from "axios";
import { UpdateCredentials } from "@/types/Credentials";
import { Query } from "./types";
import request from "./request";

export async function updateCredentials(
  data: UpdateCredentials,
  params: Query = {},
  config: AxiosRequestConfig = {}
) {
  return request({
    method: "PATCH",
    url: "/register/supplier-credentials",
    data,
    params,
    ...config,
  });
}

export function resetCemigPassword(
  data: { login: string },
  params: Query = {},
  config: AxiosRequestConfig = {}
) {
  return request({
    method: "POST",
    url: "/external/cemig/reset-password",
    data,
    params,
    ...config,
  });
}

export function registerCemigAccount(
  data: {
    email: string;
    password: string;
    name: string;
    phone: string;
    document: string;
  },
  params: Query = {},
  config: AxiosRequestConfig = {}
) {
  return request({
    method: "POST",
    url: "/external/cemig/register",
    data,
    params,
    ...config,
  });
}
