<script setup lang="ts">
import { computed, onBeforeMount } from "vue";

import { useBreakpoints } from "@/libs/metha-components";
import { useUserStore, useInvoicesStore } from "@/stores";

import LastInvoiceDetails from "@/components/organisms/LastInvoiceDetails.vue";
import InvoicesNotFound from "@/components/organisms/InvoicesNotFound.vue";
import InvoicesBlockedByInstallationStatus from "@/components/organisms/InvoicesBlockedByInstallationStatus.vue";

import SwitchStackedCards from "@/components/molecules/SwitchStackedCards.vue";
import MobileNavigation from "@/components/molecules/MobileNavigation.vue";
import ClientEconomy from "@/components/molecules/ClientEconomy.vue";
import SwipeSustainabilityStatistics from "@/components/molecules/SwipeSustainabilityStatistics.vue";
import SustainabilityStatistics from "@/components/molecules/SustainabilityStatistics.vue";
import WarningCommunication from "@/components/molecules/WarningCommunication.vue";

import { orderInvoices } from "@/utils/orderInvoices";
import { handleError } from "@/utils/handleError";
import { canSignContract } from "@/utils/contract";

const { lgAndUp } = useBreakpoints();
const userStore = useUserStore();
const invoicesStore = useInvoicesStore();

const infoPendingContract = computed(() => {
  return userStore.installations.some((installation) => {
    if (installation.status === "pending_agreement") return false;

    return canSignContract(installation);
  });
});

const hasInvoices = computed(() => {
  const invoices = invoicesStore.invoices ?? [];

  return invoices.length >= 1;
});

const infoPendingCredentials = computed(() => {
  // Confere se em alguma das INs esta com pendência de credencial.
  const installations = userStore.installations;
  for (const installation of installations) {
    if (
      installation.pending_credentials === undefined ||
      installation.pending_credentials
    ) {
      return true;
    }
  }
  return false;
});

const lastInvoice = computed(() => {
  const orderedInvoices = orderInvoices(
    invoicesStore.invoices,
    "reference_month"
  );

  return orderedInvoices[0] ?? {};
});

const totalKwhConsumption = computed(() => {
  const invoices = invoicesStore.invoices ?? [];

  return invoices.reduce(
    (acc, { kwh_consumption }) => acc + kwh_consumption,
    0
  );
});

onBeforeMount(async () => {
  try {
    userStore.setPageTitle(`Olá, ${userStore.name.split(" ")[0]}`);

    if (!userStore.selectedInstallation)
      throw Error("Sessão inválida. Favor fazer o login novamente");

    if (!hasInvoices.value) {
      await invoicesStore.getAllInvoices(userStore.selectedInstallation.id);
    }
  } catch (error) {
    handleError(error, userStore.setLoadingError);
  } finally {
    userStore.pageLoading = false;
  }
});
</script>

<template>
  <section v-show="!userStore.pageLoading" class="homepage">
    <WarningCommunication v-if="infoPendingContract" type="contract" />
    <WarningCommunication
      v-else-if="infoPendingCredentials"
      type="credential"
    />
    <InvoicesBlockedByInstallationStatus
      v-if="userStore.selectedInstallation.status === 'canceled'"
    />
    <template v-else-if="!hasInvoices">
      <InvoicesNotFound />
    </template>
    <template v-else>
      <div class="homepage__grid">
        <div class="homepage__last-invoice">
          <LastInvoiceDetails :last-invoice="lastInvoice" />
        </div>
        <div class="homepage__mobile-nav">
          <MobileNavigation />
        </div>
        <SwitchStackedCards>
          <template #secondary="{ active }">
            <SustainabilityStatistics
              v-if="lgAndUp"
              :kwhConsumption="totalKwhConsumption"
            />
            <SwipeSustainabilityStatistics
              v-else
              :kwhConsumption="totalKwhConsumption"
              :active="active"
            />
          </template>
          <template #default>
            <ClientEconomy
              class="homepage__client-economy"
              :totalEconomy="userStore.selectedInstallation.total_economy"
            />
          </template>
        </SwitchStackedCards>
      </div>
    </template>
  </section>
</template>

<style lang="scss" scoped>
.homepage {
  width: 100%;
  overflow-x: hidden;

  padding-bottom: 2rem;
  margin-top: 1rem;

  @media (min-width: 993px) {
    width: calc(100% - 2rem);
    margin: 0 auto;
  }

  @media (min-width: 1020px) {
    max-width: 1180px;
    width: calc(100% - 6rem);
  }

  &__grid {
    display: flex;
    flex-direction: column-reverse;
    gap: 1.5rem;
    margin: 2rem 1rem 0;

    @media (min-width: 993px) {
      margin: 1rem 0 0;
      display: grid;
      grid-template-areas:
        "lastInvoice consumptionSustainability"
        "lastInvoice clientEconomy";
      gap: 0.5rem;

      grid-template-columns: minmax(auto, 3fr) minmax(250px, 393px);
      grid-template-rows: repeat(2, minmax(auto, 1fr));
    }

    @media (min-width: 1180px) {
      gap: 1.5rem;
    }
  }

  &__last-invoice {
    grid-area: lastInvoice;
  }

  &__consumption-sustainability {
    grid-area: consumptionSustainability;
    height: 100%;
  }

  &__client-economy {
    grid-area: clientEconomy;
    height: 100%;
  }

  &__mobile-nav {
    @media (min-width: 993px) {
      display: none;
    }
  }
}
</style>
