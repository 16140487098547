export const navLinks = [
  {
    title: "Início",
    icon: "nm-home",
    route: "/",
  },
  {
    title: "Faturas",
    icon: "nm-document",
    route: "/faturas",
  },
  {
    title: "Instalações",
    icon: "nm-building",
    route: "/instalacoes",
  },
  {
    title: "Conta",
    icon: "nm-profile",
    route: "/conta",
  },
  {
    title: "Ajuda",
    icon: "nm-help",
    route: "/central-de-ajuda",
  },
];
