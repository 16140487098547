import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "warning-communication" }
const _hoisted_2 = { class: "warning-communication__content" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }


import { RouterLink } from "vue-router";

import ExclamationInfo from "@/assets/icons/exclamation-info.svg";


export default /*@__PURE__*/_defineComponent({
  __name: 'WarningCommunication',
  props: {
    type: {}
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        src: _unref(ExclamationInfo),
        class: "warning-communication__icon"
      }, null, 8, _hoisted_3),
      (_ctx.type === 'credential')
        ? (_openBlock(), _createElementBlock("p", _hoisted_4, [
            _cache[1] || (_cache[1] = _createTextVNode(" Você precisa vincular sua conta à concessionária para utilizar todos os nossos serviços. Acesse ")),
            _createVNode(_unref(RouterLink), { to: "/instalacoes" }, {
              default: _withCtx(() => _cache[0] || (_cache[0] = [
                _createTextVNode("Minhas Instalações")
              ])),
              _: 1
            }),
            _cache[2] || (_cache[2] = _createTextVNode(" e adicione suas credenciais. "))
          ]))
        : (_ctx.type === 'contract')
          ? (_openBlock(), _createElementBlock("p", _hoisted_5, [
              _cache[4] || (_cache[4] = _createTextVNode(" Nossos termos foram atualizados. Para continuar utilizando nossos serviços, você precisa atualizar seu contrato. Acesse ")),
              _createVNode(_unref(RouterLink), { to: "/instalacoes" }, {
                default: _withCtx(() => _cache[3] || (_cache[3] = [
                  _createTextVNode("Minhas Instalações")
                ])),
                _: 1
              }),
              _cache[5] || (_cache[5] = _createTextVNode(" e faça a atualização. "))
            ]))
          : _createCommentVNode("", true)
    ])
  ]))
}
}

})