import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment } from "vue"
import _imports_0 from '@/assets/gifs/credentials-loading.gif'
import _imports_1 from '@/assets/gifs/spinner-check.gif'
import _imports_2 from '@/assets/images/video-tutorials/tutorial-conta-criada-mobile.png'
import _imports_3 from '@/assets/images/video-tutorials/tutorial-conta-criada-desktop.jpg'
import _imports_4 from '@/assets/images/video-tutorials/video-preview-button.png'


const _hoisted_1 = { class: "cemig-installation-credentials" }
const _hoisted_2 = { class: "cemig-installation-credentials__paragraph" }
const _hoisted_3 = {
  key: 0,
  class: "cemig-installation-credentials--error-message"
}
const _hoisted_4 = { class: "cemig-installation-credentials__buttons-container" }
const _hoisted_5 = ["href"]
const _hoisted_6 = { class: "cemig-installation-credentials__safety-badge" }
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { key: 2 }
const _hoisted_9 = { class: "cemig-installation-credentials__loading" }
const _hoisted_10 = { key: 3 }
const _hoisted_11 = { class: "cemig-installation-credentials__success__cards-container" }
const _hoisted_12 = { key: 4 }
const _hoisted_13 = {
  key: 0,
  class: "cemig-installation-credentials--error-message"
}
const _hoisted_14 = { class: "cemig-installation-credentials__buttons-container" }
const _hoisted_15 = { key: 5 }
const _hoisted_16 = { class: "cemig-installation-credentials__buttons-container" }
const _hoisted_17 = { key: 6 }
const _hoisted_18 = {
  key: 0,
  src: _imports_2,
  alt: "Thumbnail do vídeo",
  class: "cemig-installation-credentials__access__video-thumbnail"
}
const _hoisted_19 = {
  key: 1,
  src: _imports_3,
  alt: "Thumbnail do vídeo",
  class: "cemig-installation-credentials__access__video-thumbnail"
}
const _hoisted_20 = { class: "cemig-installation-credentials__access__modal-container" }
const _hoisted_21 = { key: 7 }
const _hoisted_22 = { class: "cemig-installation-credentials__paragraph" }
const _hoisted_23 = ["href"]
const _hoisted_24 = { class: "cemig-installation-credentials__buttons-container" }
const _hoisted_25 = { key: 8 }
const _hoisted_26 = { class: "cemig-installation-credentials__paragraph" }

import { computed, watch, ref, onMounted } from "vue";
import { Form } from "vee-validate";

import {
  MeTextField,
  MeButton,
  MeIcon,
  MeModal,
  useBreakpoints,
} from "@/libs/metha-components";

import { useCredentialsStore, useLoginStore, useUserStore } from "@/stores";

import CaSpinner from "@/components/atoms/CaSpinner.vue";
import SuccessInstallationCard from "@/components/atoms/InstallationTile.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'CemigInstallationCredentials',
  props: {
  submit: {
    type: Function,
    required: true,
  },
  createAccount: {
    type: Function,
    required: true,
  },
  forgotPasswordLink: {
    type: String,
    required: true,
  },
  redefinePassword: {
    type: Function,
    required: true,
  },
  closeModal: {
    type: Function,
    required: true,
  },
},
  setup(__props) {

const props = __props;

const { mdAndDown } = useBreakpoints();
const userStore = useUserStore();
const loginStore = useLoginStore();
const credentialsStore = useCredentialsStore();

const userLogin = ref("");
const userPassword = ref("");
const confirmUserPassword = ref("");
const openTutorialModal = ref(false);
const successFeedbackContent = ref();

const userEmail = computed(() => {
  if (loginStore.email.trim() !== "") return loginStore.email;

  return userStore.email;
});

const supplier = computed(() => "cemig".toUpperCase());

const hasExternalError = computed(() =>
  credentialsStore.loginError ? " " : undefined
);

function changeCredentialsStep(newStatus: string) {
  userLogin.value = loginStore.cpf ?? "";
  userPassword.value = "";
  credentialsStore.step = newStatus;
}

function registerNewAccount() {
  if (!userPassword.value.trim() || !confirmUserPassword.value.trim()) {
    credentialsStore.loginError = "Preencha todos os campos";
    return;
  }

  return props.createAccount(
    userEmail.value,
    userPassword.value,
    confirmUserPassword.value
  );
}

function login() {
  if (!userLogin.value.trim() || !userPassword.value.trim()) {
    credentialsStore.loginError = "Preencha todos os campos";
    return;
  }

  props.submit(userLogin.value, userPassword.value);
}

watch(
  () => ({
    login: userLogin.value,
    password: userPassword.value,
  }),
  () => {
    credentialsStore.loginError = "";
  }
);

onMounted(() => {
  credentialsStore.resetLogin();

  userLogin.value = (
    credentialsStore.installationLogin ?? userStore.cpf
  ).replace(/[^\d]+/g, "");
});

return (_ctx: any,_cache: any) => {
  const _directive_maska = _resolveDirective("maska")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_unref(credentialsStore).step === '')
      ? (_openBlock(), _createBlock(_unref(Form), {
          key: 0,
          onSubmit: login
        }, {
          default: _withCtx(() => [
            _createElementVNode("p", _hoisted_2, " Faça o login com as credenciais usadas para acessar o site da " + _toDisplayString(supplier.value) + ". ", 1),
            _withDirectives(_createVNode(_unref(MeTextField), {
              modelValue: userLogin.value,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((userLogin).value = $event)),
              label: "CPF/CNPJ do usuário",
              rules: "required",
              disabled: ""
            }, null, 8, ["modelValue"]), [
              [_directive_maska, ['###.###.###-##', '##.###.###/####-##']]
            ]),
            _createVNode(_unref(MeTextField), {
              modelValue: userPassword.value,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((userPassword).value = $event)),
              label: "Senha",
              rules: "required",
              type: "password"
            }, null, 8, ["modelValue"]),
            (_unref(credentialsStore).loginError.trim() !== '')
              ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_unref(credentialsStore).loginError), 1))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_unref(MeButton), {
                block: "",
                disabled: 
            _unref(credentialsStore).loading ||
            !!(
              _unref(credentialsStore).loginError &&
              _unref(credentialsStore).loginError.length > 0
            )
          
              }, {
                default: _withCtx(() => _cache[16] || (_cache[16] = [
                  _createTextVNode(" Acessar ")
                ])),
                _: 1
              }, 8, ["disabled"]),
              _createElementVNode("p", {
                onClick: _cache[2] || (_cache[2] = ($event: any) => (changeCredentialsStep('registering'))),
                class: "me--link"
              }, " Não tenho um acesso " + _toDisplayString(supplier.value), 1),
              _createElementVNode("a", {
                href: __props.forgotPasswordLink,
                target: "_blank",
                class: "me--link"
              }, " Esqueci minha senha ", 8, _hoisted_5)
            ]),
            _createElementVNode("p", _hoisted_6, [
              _createVNode(_unref(MeIcon), null, {
                default: _withCtx(() => _cache[17] || (_cache[17] = [
                  _createTextVNode("shield")
                ])),
                _: 1
              }),
              _cache[18] || (_cache[18] = _createTextVNode(" Ambiente 100% seguro "))
            ])
          ]),
          _: 1
        }))
      : (_unref(credentialsStore).step === 'loginLoading')
        ? (_openBlock(), _createElementBlock("div", _hoisted_7, _cache[19] || (_cache[19] = [
            _createElementVNode("div", { class: "cemig-installation-credentials__login-loading" }, [
              _createElementVNode("img", {
                src: _imports_0,
                preload: "",
                loading: "eager"
              })
            ], -1)
          ])))
        : (_unref(credentialsStore).step === 'loading')
          ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
              _createElementVNode("div", _hoisted_9, [
                _createVNode(CaSpinner)
              ])
            ]))
          : (_unref(credentialsStore).step === 'success')
            ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                _createElementVNode("div", {
                  ref_key: "successFeedbackContent",
                  ref: successFeedbackContent,
                  class: "cemig-installation-credentials__success"
                }, [
                  _cache[21] || (_cache[21] = _createElementVNode("div", null, [
                    _createElementVNode("img", {
                      src: _imports_1,
                      alt: "",
                      width: "80",
                      height: "80",
                      class: "cemig-installation-credentials__success__icon"
                    }),
                    _createElementVNode("p", { class: "cemig-installation-credentials__success__title" }, " Credenciais atualizadas com sucesso "),
                    _createElementVNode("p", { class: "cemig-installation-credentials__success__text" }, " Estas são as instalações associadas à sua conta CEMIG que tiveram as credenciais atualizadas: ")
                  ], -1)),
                  _createElementVNode("div", _hoisted_11, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(credentialsStore).updatedCredentialsInstallations, (installation, index) => {
                      return (_openBlock(), _createBlock(SuccessInstallationCard, {
                        key: `cemig-installation-credentials-success-item-${index}`,
                        installation: installation
                      }, null, 8, ["installation"]))
                    }), 128))
                  ]),
                  _createElementVNode("div", null, [
                    _createVNode(_unref(MeButton), {
                      variant: "outline",
                      onClick: _cache[3] || (_cache[3] = () => __props.closeModal())
                    }, {
                      default: _withCtx(() => _cache[20] || (_cache[20] = [
                        _createTextVNode("Fechar")
                      ])),
                      _: 1
                    })
                  ])
                ], 512)
              ]))
            : (_unref(credentialsStore).step === 'registering')
              ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                  _cache[23] || (_cache[23] = _createElementVNode("p", { class: "cemig-installation-credentials__paragraph" }, " Sem problemas! Ajudaremos você a criar uma conta. É rápido, basta definir sua senha de acesso ", -1)),
                  _createVNode(_unref(MeTextField), {
                    modelValue: userEmail.value,
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((userEmail).value = $event)),
                    label: "",
                    disabled: ""
                  }, null, 8, ["modelValue"]),
                  _createVNode(_unref(MeTextField), {
                    modelValue: userPassword.value,
                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((userPassword).value = $event)),
                    label: "Digite uma senha",
                    rules: "required|min:6",
                    type: "password",
                    error: hasExternalError.value
                  }, null, 8, ["modelValue", "error"]),
                  _createVNode(_unref(MeTextField), {
                    modelValue: confirmUserPassword.value,
                    "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((confirmUserPassword).value = $event)),
                    label: "Confirme a senha",
                    rules: "required",
                    type: "password",
                    error: hasExternalError.value
                  }, null, 8, ["modelValue", "error"]),
                  (_unref(credentialsStore).loginError.trim() !== '')
                    ? (_openBlock(), _createElementBlock("p", _hoisted_13, _toDisplayString(_unref(credentialsStore).loginError), 1))
                    : _createCommentVNode("", true),
                  _createElementVNode("div", _hoisted_14, [
                    _createVNode(_unref(MeButton), {
                      onClick: registerNewAccount,
                      disabled: _unref(credentialsStore).loading,
                      block: ""
                    }, {
                      default: _withCtx(() => _cache[22] || (_cache[22] = [
                        _createTextVNode(" Criar conta ")
                      ])),
                      _: 1
                    }, 8, ["disabled"]),
                    _createElementVNode("p", {
                      onClick: _cache[7] || (_cache[7] = ($event: any) => (changeCredentialsStep(''))),
                      class: "cemig-installation-credentials__button me--link"
                    }, " Voltar ")
                  ])
                ]))
              : (_unref(credentialsStore).step === 'hasAccount')
                ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                    _cache[26] || (_cache[26] = _createElementVNode("p", { class: "cemig-installation-credentials__paragraph" }, " Você já possui uma conta no seu CPF e/ou email. Você pode solicitar para enviar um email para redefinir a sua senha. ", -1)),
                    _createElementVNode("div", _hoisted_16, [
                      _createVNode(_unref(MeButton), {
                        onClick: _cache[8] || (_cache[8] = () => __props.redefinePassword()),
                        disabled: _unref(credentialsStore).loading,
                        type: "button",
                        variant: "outline",
                        block: ""
                      }, {
                        default: _withCtx(() => _cache[24] || (_cache[24] = [
                          _createTextVNode(" Redefinir senha ")
                        ])),
                        _: 1
                      }, 8, ["disabled"]),
                      _createVNode(_unref(MeButton), {
                        type: "button",
                        onClick: _cache[9] || (_cache[9] = ($event: any) => (changeCredentialsStep(''))),
                        block: ""
                      }, {
                        default: _withCtx(() => _cache[25] || (_cache[25] = [
                          _createTextVNode(" Voltar ")
                        ])),
                        _: 1
                      })
                    ])
                  ]))
                : (_unref(credentialsStore).step === 'createdAccount')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                      _cache[31] || (_cache[31] = _createElementVNode("p", { class: "cemig-installation-credentials__paragraph" }, " Caro usuário, enviamos um e-mail de confirmação de cadastro. Favor verificar e seguir as instruções do vídeo abaixo. ", -1)),
                      _createElementVNode("div", {
                        onClick: _cache[10] || (_cache[10] = ($event: any) => (openTutorialModal.value = true)),
                        class: "cemig-installation-credentials__access__video"
                      }, [
                        (_unref(mdAndDown))
                          ? (_openBlock(), _createElementBlock("img", _hoisted_18))
                          : (_openBlock(), _createElementBlock("img", _hoisted_19)),
                        _cache[27] || (_cache[27] = _createElementVNode("img", {
                          src: _imports_4,
                          alt: "Botão para iniciar o vídeo",
                          class: "cemig-installation-credentials__access__video-button"
                        }, null, -1))
                      ]),
                      _createVNode(_unref(MeButton), {
                        type: "button",
                        onClick: _cache[11] || (_cache[11] = ($event: any) => (changeCredentialsStep(''))),
                        block: ""
                      }, {
                        default: _withCtx(() => _cache[28] || (_cache[28] = [
                          _createTextVNode(" Voltar ")
                        ])),
                        _: 1
                      }),
                      _createVNode(_unref(MeModal), {
                        modelValue: openTutorialModal.value,
                        "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((openTutorialModal).value = $event))
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_20, [
                            _cache[30] || (_cache[30] = _createElementVNode("iframe", {
                              src: "https://www.youtube.com/embed/WF197OUATfw?autoplay=1",
                              title: "YouTube video player",
                              frameborder: "0",
                              allow: "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share",
                              allowfullscreen: "",
                              autoplay: "",
                              class: "cemig-installation-credentials__access__modal-video"
                            }, null, -1)),
                            _createElementVNode("div", {
                              onClick: _cache[12] || (_cache[12] = ($event: any) => (openTutorialModal.value = false)),
                              class: "cemig-installation-credentials__access__modal-close-button"
                            }, [
                              _createElementVNode("span", null, [
                                _createVNode(_unref(MeIcon), null, {
                                  default: _withCtx(() => _cache[29] || (_cache[29] = [
                                    _createTextVNode("x")
                                  ])),
                                  _: 1
                                })
                              ])
                            ])
                          ])
                        ]),
                        _: 1
                      }, 8, ["modelValue"])
                    ]))
                  : (_unref(credentialsStore).step === 'forgotPasswordSent')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
                        _cache[34] || (_cache[34] = _createElementVNode("p", { class: "mt-6 cemig-installation-credentials__subtitle" }, " Enviamos um link para o seu email cadastrado ", -1)),
                        _createElementVNode("p", _hoisted_22, [
                          _cache[32] || (_cache[32] = _createTextVNode(" Se o link não chegar à sua caixa de entrada em minutos, verifique a pasta de spam ou ")),
                          _createElementVNode("a", {
                            href: __props.forgotPasswordLink,
                            target: "_blank"
                          }, "clique aqui", 8, _hoisted_23),
                          _createTextVNode(" para recuperar a sua senha pelo site da " + _toDisplayString(supplier.value) + ". ", 1)
                        ]),
                        _createElementVNode("div", _hoisted_24, [
                          _createVNode(_unref(MeButton), {
                            type: "button",
                            onClick: _cache[14] || (_cache[14] = ($event: any) => (changeCredentialsStep(''))),
                            block: ""
                          }, {
                            default: _withCtx(() => _cache[33] || (_cache[33] = [
                              _createTextVNode(" Voltar ")
                            ])),
                            _: 1
                          })
                        ])
                      ]))
                    : (_openBlock(), _createElementBlock("div", _hoisted_25, [
                        _createElementVNode("p", _hoisted_26, _toDisplayString(_unref(credentialsStore).loginError), 1),
                        _createVNode(_unref(MeButton), {
                          type: "button",
                          onClick: _cache[15] || (_cache[15] = ($event: any) => (changeCredentialsStep(''))),
                          block: ""
                        }, {
                          default: _withCtx(() => _cache[35] || (_cache[35] = [
                            _createTextVNode(" Voltar ")
                          ])),
                          _: 1
                        })
                      ]))
  ]))
}
}

})