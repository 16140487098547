export function clearObjectEmptyFields(obj: any) {
  const result = {} as any;

  for (const key in obj) {
    const emptyString =
      typeof obj[key] === "string" ? obj[key].trim().length < 1 : false;

    if (obj[key] !== null && obj[key] !== undefined && !emptyString) {
      result[key] = obj[key];
    }
  }
  return result;
}
