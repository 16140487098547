function validateCPF(cpf: string) {
  let sum = 0;
  let rest;

  const strCPF = cpf.replace(/[^\d]+/g, "");

  if (strCPF == "") return false;
  if (strCPF.length != 11) return false;

  if (strCPF == "00000000000") return false;

  for (let i = 1; i <= 9; i++)
    sum = sum + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
  rest = (sum * 10) % 11;

  if (rest == 10 || rest == 11) rest = 0;
  if (rest != parseInt(strCPF.substring(9, 10))) return false;

  sum = 0;
  for (let i = 1; i <= 10; i++)
    sum = sum + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
  rest = (sum * 10) % 11;

  if (rest == 10 || rest == 11) rest = 0;
  if (rest != parseInt(strCPF.substring(10, 11))) return false;
  return true;
}

// https://www.geradorcnpj.com/javascript-validar-cnpj.htm
function validateCNPJ(cnpj: string) {
  cnpj = cnpj.replace(/[^\d]+/g, "");

  if (cnpj == "") return false;
  if (cnpj.length != 14) return false;

  if (
    cnpj == "00000000000000" ||
    cnpj == "11111111111111" ||
    cnpj == "22222222222222" ||
    cnpj == "33333333333333" ||
    cnpj == "44444444444444" ||
    cnpj == "55555555555555" ||
    cnpj == "66666666666666" ||
    cnpj == "77777777777777" ||
    cnpj == "88888888888888" ||
    cnpj == "99999999999999"
  )
    return false;

  return CNPJValidator(cnpj);
}

// https://answall.com/q/153173/how-to-validate-cnpj/
function CNPJValidator(cnpj: string) {
  function getVerificationCode1(cnpj: string) {
    let total = 0;
    let mod = 0;
    const factors = [5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];
    const nums = cnpj.substr(0, 12).split("");
    for (const i in nums) {
      total += +nums[i] * factors[i];
    }
    mod = total % 11;
    return mod < 2 ? 0 : 11 - mod;
  }

  function getVerificationCode2(cnpj: string, code1: number) {
    let total = 0;
    let mod = 0;
    const factors = [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];
    const nums = (cnpj.substr(0, 12) + code1).split("");
    for (const i in nums) {
      total += +nums[i] * factors[i as any];
    }
    mod = total % 11;
    return mod < 2 ? 0 : 11 - mod;
  }

  const normalizedCNPJ = cnpj.replace(/[^0-9]/g, "");

  const verificationCode1 = +normalizedCNPJ.substr(-2, 1);
  const verificationCode2 = +normalizedCNPJ.substr(-1, 1);

  const code1 = getVerificationCode1(normalizedCNPJ);
  const code2 = getVerificationCode2(normalizedCNPJ, code1);

  return code1 == verificationCode1 && code2 == verificationCode2;
}

export { validateCNPJ, validateCPF };
