import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createBlock as _createBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "contact-update-modal" }
const _hoisted_2 = {
  key: 0,
  class: "contact-update-modal__icon"
}
const _hoisted_3 = { class: "contact-update-modal__title" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = { class: "contact-update-modal__content" }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = {
  key: 1,
  class: "meOtpCode"
}

import { ref } from "vue";
import {
  MeIcon,
  MeButton,
  MeTextField,
  MeOtpCode,
} from "@/libs/metha-components";
import { UpdateContactObject } from "@/types/UserTypes";
import CaModal from "@/components/atoms/CaModal.vue";
import { useUserStore } from "@/stores";
import { toast } from "vue3-toastify";

export default /*@__PURE__*/_defineComponent({
  __name: 'ContactUpdateModal',
  props: {
    type: {},
    closeButtonText: {},
    closeModal: { type: Function }
  },
  setup(__props: any) {

const userStore = useUserStore();
const props = __props;
const stage = ref(1);
const otpCode = ref("");
const contactUser = ref("");
const contactTitle = ref("");
const contactText = ref("");
const contactType = ref(props.type);
const email = ref("");
const phone = ref("");
const buttonText = ref(props.closeButtonText);
const texts: any = {
  email: {
    title: "Alterar meu e-mail",
    text: [
      "Digite seu novo e-mail abaixo:",
      "Enviamos um código de confirmação para o e-mail:",
    ],
  },
  phone: {
    title: "Alterar meu celular",
    text: [
      "Digite seu novo número de celular abaixo:",
      "Enviamos um código de confirmação para o celular:",
    ],
  },
};

function setContentText(type: string, stageNumber: number) {
  // stageNumber é referente a variável stage que é estágio que se encontra a etapa do formulário
  if (stageNumber === 1) {
    contactTitle.value = texts[type].title;
  } else if (stageNumber === 3) {
    contactTitle.value = "Informação de contato atualizada!";
    contactText.value =
      "Obrigado por manter suas informações atualizadas conosco!";
    buttonText.value = "Fechar";
  } else {
    contactText.value = texts[type].text[stageNumber - 1];
  }
}

async function updateContactUser() {
  userStore.loadingScreen = true;
  let payload = {
    type: contactType.value,
  } as UpdateContactObject;
  if (props.type === "email") {
    payload["value"] = email.value;
  } else {
    payload["value"] = phone.value;
  }
  payload["code"] = otpCode.value;
  const returnStatus = await userStore.updateContactUser(payload);
  if (returnStatus === "OK") {
    stage.value++;
  } else {
    email.value = "";
    phone.value = "";
    stage.value = 1;
  }
  setContentText(contactType.value, stage.value);
  userStore.loadingScreen = false;
}

async function updateContactSendOtpCode() {
  const sendOtpCode = await userStore.updateContactSendOtpCode({
    type: contactType.value,
    value: contactUser.value,
  });
  if (sendOtpCode.status === "error") initStage();
}

function warning() {
  toast.error("Preencha o campo corretamente!", {
    position: toast.POSITION.BOTTOM_CENTER,
  });
}

function actions() {
  // stage é o estágio que se encontra a etapa do formulário
  // 1 é o preenchimento do contato, email ou telefone. 2 é o preenchimento do código Otp e 3 a resposta de sucesso.
  if (stage.value === 3) {
    props.closeModal();
    return;
  }
  if (stage.value === 2) {
    updateContactUser();
  }
  if (stage.value === 1) {
    const isPhoneAndEmpty = contactType.value === "phone" && phone.value === "";
    const isEmailAndEmpty = contactType.value === "email" && email.value === "";
    if (isEmailAndEmpty || isPhoneAndEmpty) {
      warning();
      return;
    }
    contactUser.value =
      contactType.value === "email" ? email.value : phone.value;
    stage.value++;
    setContentText(contactType.value, stage.value);
    updateContactSendOtpCode();
  }
}

function initStage() {
  email.value = "";
  phone.value = "";
  stage.value = 1;
  setContentText(contactType.value, stage.value);
}

setContentText(contactType.value, stage.value);

return (_ctx: any,_cache: any) => {
  const _directive_maska = _resolveDirective("maska")!

  return (_openBlock(), _createBlock(CaModal, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", {
          class: "contact-update-modal__close-button",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.closeModal && _ctx.closeModal(...args)))
        }, [
          _createVNode(_unref(MeIcon), null, {
            default: _withCtx(() => _cache[4] || (_cache[4] = [
              _createTextVNode("x")
            ])),
            _: 1
          })
        ]),
        (stage.value === 3)
          ? (_openBlock(), _createElementBlock("span", _hoisted_2, [
              _createVNode(_unref(MeIcon), null, {
                default: _withCtx(() => _cache[5] || (_cache[5] = [
                  _createTextVNode("check")
                ])),
                _: 1
              })
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("h2", _hoisted_3, _toDisplayString(contactTitle.value), 1),
        _createElementVNode("p", {
          class: "contact-update-modal__text",
          innerHTML: contactText.value
        }, null, 8, _hoisted_4),
        (stage.value === 2)
          ? (_openBlock(), _createElementBlock("p", {
              key: 1,
              class: "contact-update-modal__text__bold",
              innerHTML: contactUser.value
            }, null, 8, _hoisted_5))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_6, [
          (stage.value === 1)
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                (_ctx.type === 'email')
                  ? (_openBlock(), _createBlock(_unref(MeTextField), {
                      key: 0,
                      label: "E-mail",
                      name: "email",
                      modelValue: email.value,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((email).value = $event)),
                      rules: "email|required",
                      class: "meTextField"
                    }, null, 8, ["modelValue"]))
                  : _withDirectives((_openBlock(), _createBlock(_unref(MeTextField), {
                      key: 1,
                      label: "Celular",
                      name: "phone",
                      modelValue: phone.value,
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((phone).value = $event)),
                      rules: "required|phone",
                      class: "meTextField"
                    }, null, 8, ["modelValue"])), [
                      [_directive_maska, '(##) #####-####']
                    ])
              ]))
            : _createCommentVNode("", true),
          (stage.value === 2)
            ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                _cache[6] || (_cache[6] = _createElementVNode("p", { class: "contact-update-modal__uppercase" }, "código de autorização", -1)),
                _createVNode(_unref(MeOtpCode), {
                  modelValue: otpCode.value,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((otpCode).value = $event))
                }, null, 8, ["modelValue"])
              ]))
            : _createCommentVNode("", true),
          (_ctx.closeButtonText && _ctx.closeButtonText.length)
            ? (_openBlock(), _createBlock(_unref(MeButton), {
                key: 2,
                onClick: actions,
                size: "large",
                block: "",
                class: "meButton"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(buttonText.value), 1)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    _: 1
  }))
}
}

})