import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "success-installation-card" }
const _hoisted_2 = { class: "success-installation-card__header" }
const _hoisted_3 = { class: "success-installation-card__header__title" }
const _hoisted_4 = ["src", "alt"]
const _hoisted_5 = { class: "success-installation-card__description" }


import type FlatInstallation from "@/utils/InstallationTypes";

import { getSupplierLogo } from "@/utils/supplierlogo";

export default /*@__PURE__*/_defineComponent({
  __name: 'InstallationTile',
  props: {
    installation: {}
  },
  setup(__props: any) {




return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.installation.installation_number), 1),
      _createElementVNode("img", {
        src: _unref(getSupplierLogo)(_ctx.installation.supplier),
        alt: `${_ctx.installation.supplier} logo`,
        class: "success-installation-card__header__supplier-logo"
      }, null, 8, _hoisted_4)
    ]),
    _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.installation.address), 1)
  ]))
}
}

})