<script setup lang="ts">
import { computed, defineProps, ref } from "vue";

import { useBreakpoints, MeButton } from "@/libs/metha-components";
import { useCredentialsStore } from "@/stores";

import { FlatInstallation } from "@/types/InstallationTypes";

import Spinner from "@/components/atoms/CaSpinner.vue";
import BulletStatusTag from "@/components/atoms/BulletStatusTag.vue";

const props = defineProps<{
  installation: FlatInstallation;
}>();

const { lgAndUp } = useBreakpoints();
const credentialsStore = useCredentialsStore();

const opening = ref(false);

const isCredentialsUpdate = computed(() => {
  return typeof props.installation.pending_credentials === "boolean";
});

function openCredentials() {
  credentialsStore.setInstallationLogin(props.installation);
  credentialsStore.openCredentialsModal();
}
</script>

<template>
  <div
    :class="[
      'installation-credential',
      { 'installation-credential--loading': opening },
    ]"
  >
    <template v-if="opening">
      <div class="installation-credential__icon">
        <Spinner :size="20" />
      </div>
      <p class="installation-credential__text">Carregando...</p>
    </template>
    <template v-else>
      <BulletStatusTag
        v-if="lgAndUp"
        definedText="Credenciais pendentes"
        status="reproved"
      />
      <p class="installation-credential__text">
        <b>Atenção!</b> {{ isCredentialsUpdate ? "Atualize" : "Adicione" }} as
        credenciais de acesso à concessionária para receber todos os serviços da
        Metha.
      </p>
      <div class="installation-credential__button">
        <MeButton block @click="openCredentials">
          {{ isCredentialsUpdate ? "Atualizar" : "Adicionar" }} credenciais
        </MeButton>
      </div>
    </template>
  </div>
</template>

<style lang="scss" scoped>
.installation-credential {
  position: relative;
  background-color: #e9ecef;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  padding: 1rem 1.5rem;
  gap: 0.65rem;

  @media (min-width: 993px) {
    align-items: flex-start;
  }

  &--loading {
    opacity: 0.7;
  }

  &__text {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;

    @media (min-width: 993px) {
      font-size: 1.125rem;
      line-height: 1.6875rem;
    }
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 18px;
    font-size: 18px;

    @media (min-width: 993px) {
      width: 20px;
      font-size: 20px;
    }
  }

  &__button {
    width: 100%;

    @media (min-width: 650px) {
      width: fit-content;
    }
  }
}
</style>
