<script setup lang="ts">
import { defineProps, computed, PropType } from "vue";

import cpflLogo from "@/assets/images/supplier/cpfl-paulista-logo.png";
import cemigLogo from "@/assets/images/supplier/cemig-logo.png";
import energisaLogo from "@/assets/images/supplier/energisa-mt-logo.png";

const props = defineProps({
  title: {
    type: String,
    default: "Adicionar instalação",
  },
  supplier: {
    type: String as PropType<"cemig" | "cpfl-paulista" | "energisa-mt">,
    required: true,
  },
  hideSupplierInfo: Boolean,
});

const logoImg = computed(() => {
  const logos = {
    "cpfl-paulista": cpflLogo,
    cemig: cemigLogo,
    "energisa-mt": energisaLogo,
  };

  return logos[props.supplier as keyof typeof logos] || "";
});
</script>

<template>
  <div class="installation-access">
    <div class="installation-access__wrapper">
      <div class="installation-access__content">
        <img
          v-if="!hideSupplierInfo"
          :src="logoImg"
          alt="Logo da concessionária"
          height="80"
          class="installation-access__content__supplier-logo"
        />
        <slot></slot>
      </div>
      <p v-if="!hideSupplierInfo" class="installation-access__policy">
        *O acesso a concessionária é solicitado apenas para leitura de dados.
        Não iremos alterar algum dado sem a permissão prévia.
        <a
          href="https://www.methaenergia.com.br/politica-de-privacidade"
          target="_blank"
        >
          Clique aqui para acessar os termos de privacidade.
        </a>
      </p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.installation-access {
  width: 100%;
  max-height: 90vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  padding-top: 1.5rem;

  @media (min-width: 993px) {
    padding-top: 0;
  }

  &__wrapper {
    max-width: 480px;

    @media (min-width: 993px) {
      max-width: 580px;
    }
  }

  &__content {
    background: #fff;
    padding-bottom: 1.5rem;

    @media (min-width: 993px) {
      padding: 1.5rem;
    }

    &__supplier-logo {
      display: block;
      width: auto;
      margin: 0 auto;
    }
  }

  &__policy {
    color: #6c757d;
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1.125rem;
  }
}
</style>
