import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/icons/x.svg'


const _hoisted_1 = { class: "metha-credentials" }
const _hoisted_2 = { class: "metha-credentials__modal" }

import { computed } from "vue";

import api from "@/api";
import { useCredentialsStore, useUserStore } from "@/stores";

import { handleError } from "@/utils/handleError";

import CemigComponent from "@/components/molecules/MethaCredentials/CemigInstallationCredentials.vue";
import DefaultCredentialsLayout from "@/components/molecules/MethaCredentials/DefaultCredentialsLayout.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'MethaCredentials',
  setup(__props) {

const userStore = useUserStore();
const credentialsStore = useCredentialsStore();

const hideSupplierInfo = computed(() => {
  return ["success"].includes(credentialsStore.step);
});

const isDispatchingRequest = computed(() => {
  return ["loading", "loginLoading"].includes(credentialsStore.step);
});

function closeModal() {
  if (isDispatchingRequest.value) return;

  if (credentialsStore.step === "success")
    credentialsStore.clearUpdatedCredentialsInstallations();

  credentialsStore.closeCredentialsModal();
}

async function registerCemigAccount(payload: {
  email: string;
  password: string;
  name: string;
  phone: string;
  document: string;
}) {
  try {
    await api.external.registerCemigAccount(payload);
    return { error: null };
  } catch (err) {
    let error = "";

    handleError(err, (errMessage: string) => {
      error = errMessage;
    });

    return { error };
  }
}

async function resetPassword(payload: { login: string }) {
  try {
    await api.external.resetCemigPassword(payload);
    return { error: null };
  } catch (err) {
    let error = "";

    handleError(err, (errMessage: string) => {
      error = errMessage;
    });

    return { error };
  }
}

async function updateCredentials(login: string, password: string) {
  if (credentialsStore.loading) return;
  credentialsStore.loading = true;

  try {
    credentialsStore.loginError = "";
    credentialsStore.step = "loginLoading";

    const data = await credentialsStore.updateUserCredentials(
      login,
      password,
      "cemig"
    );

    if (credentialsStore.loginError && credentialsStore.loginError.length > 0) {
      credentialsStore.loading = false;
      return;
    }

    credentialsStore.updateInstallationsCredentialsStatus(data);

    credentialsStore.step = "success";
  } catch (error) {
    handleError(error, (err: string) => {
      credentialsStore.loginError = err;
    });
  } finally {
    credentialsStore.loading = false;
  }
}

async function sendResetPasswordEmail(message: string) {
  if (credentialsStore.step === "loading") return;

  credentialsStore.step = "loading";

  credentialsStore.loginError = message;
  const { error } = await resetPassword({ login: userStore.cpf });

  if (error) {
    // Reativar o tratamento de erro retornado quando ele estiver funcionando melhor (com mensagens de erro mais 'user-fliendly')
    // credentialsStore.loginError = error;
    credentialsStore.loginError =
      "Não foi possível enviar o e-mail para sua redefinição de senha. Tente novamente por favor.";
  }

  credentialsStore.step = "error";
}

async function createAccount(
  email: string,
  password: string,
  confirmPassword: string
) {
  if (password !== confirmPassword) {
    credentialsStore.loginError = "As senhas precisam ser iguais";
    return;
  }

  if (credentialsStore.step === "loading") return;

  credentialsStore.step = "loading";

  const name = userStore.name;
  const normalizedPhone = userStore.phone.replace(/\D/gm, "");
  const document = userStore.cpf.replace(/\D/gm, "");

  const { error } = await registerCemigAccount({
    email,
    password,
    name,
    phone: normalizedPhone,
    document,
  });

  if (error) {
    // Reativar o tratamento de erro retornado quando ele estiver funcionando melhor (com mensagens de erro mais 'user-fliendly')
    // credentialsStore.loginError = error;
    credentialsStore.loginError =
      "Não foi possível cadastrar sua conta no momento. Tente novamente mais tarde por favor.";
    credentialsStore.step = "error";
    return;
  }

  credentialsStore.step = "";
}

async function redefinePassword() {
  await sendResetPasswordEmail(
    "O link para redefinição de senha foi enviado para para o e-mail cadastrado."
  );
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: "metha-credentials__modal__close-button",
        onClick: closeModal
      }, _cache[0] || (_cache[0] = [
        _createElementVNode("img", {
          src: _imports_0,
          width: "10",
          height: "10"
        }, null, -1)
      ])),
      _createVNode(DefaultCredentialsLayout, {
        supplier: "cemig",
        hideSupplierInfo: hideSupplierInfo.value
      }, {
        default: _withCtx(() => [
          _createVNode(CemigComponent, {
            submit: updateCredentials,
            createAccount: createAccount,
            forgotPasswordLink: "https://www.atendimento.cemig.com.br/portal/auth/password/recovery",
            redefinePassword: redefinePassword,
            closeModal: closeModal
          })
        ]),
        _: 1
      }, 8, ["hideSupplierInfo"])
    ])
  ]))
}
}

})