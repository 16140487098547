import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "installation-access" }
const _hoisted_2 = { class: "installation-access__wrapper" }
const _hoisted_3 = { class: "installation-access__content" }
const _hoisted_4 = ["src"]
const _hoisted_5 = {
  key: 0,
  class: "installation-access__policy"
}

import { computed, PropType } from "vue";

import cpflLogo from "@/assets/images/supplier/cpfl-paulista-logo.png";
import cemigLogo from "@/assets/images/supplier/cemig-logo.png";
import energisaLogo from "@/assets/images/supplier/energisa-mt-logo.png";


export default /*@__PURE__*/_defineComponent({
  __name: 'DefaultCredentialsLayout',
  props: {
  title: {
    type: String,
    default: "Adicionar instalação",
  },
  supplier: {
    type: String as PropType<"cemig" | "cpfl-paulista" | "energisa-mt">,
    required: true,
  },
  hideSupplierInfo: Boolean,
},
  setup(__props) {

const props = __props;

const logoImg = computed(() => {
  const logos = {
    "cpfl-paulista": cpflLogo,
    cemig: cemigLogo,
    "energisa-mt": energisaLogo,
  };

  return logos[props.supplier as keyof typeof logos] || "";
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (!__props.hideSupplierInfo)
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              src: logoImg.value,
              alt: "Logo da concessionária",
              height: "80",
              class: "installation-access__content__supplier-logo"
            }, null, 8, _hoisted_4))
          : _createCommentVNode("", true),
        _renderSlot(_ctx.$slots, "default")
      ]),
      (!__props.hideSupplierInfo)
        ? (_openBlock(), _createElementBlock("p", _hoisted_5, _cache[0] || (_cache[0] = [
            _createTextVNode(" *O acesso a concessionária é solicitado apenas para leitura de dados. Não iremos alterar algum dado sem a permissão prévia. "),
            _createElementVNode("a", {
              href: "https://www.methaenergia.com.br/politica-de-privacidade",
              target: "_blank"
            }, " Clique aqui para acessar os termos de privacidade. ", -1)
          ])))
        : _createCommentVNode("", true)
    ])
  ]))
}
}

})