import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, createBlock as _createBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "installation-credential__icon" }
const _hoisted_2 = { class: "installation-credential__text" }
const _hoisted_3 = { class: "installation-credential__button" }

import { computed, ref } from "vue";

import { useBreakpoints, MeButton } from "@/libs/metha-components";
import { useCredentialsStore } from "@/stores";

import { FlatInstallation } from "@/types/InstallationTypes";

import Spinner from "@/components/atoms/CaSpinner.vue";
import BulletStatusTag from "@/components/atoms/BulletStatusTag.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'InstallationCredential',
  props: {
    installation: {}
  },
  setup(__props: any) {

const props = __props;

const { lgAndUp } = useBreakpoints();
const credentialsStore = useCredentialsStore();

const opening = ref(false);

const isCredentialsUpdate = computed(() => {
  return typeof props.installation.pending_credentials === "boolean";
});

function openCredentials() {
  credentialsStore.setInstallationLogin(props.installation);
  credentialsStore.openCredentialsModal();
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([
      'installation-credential',
      { 'installation-credential--loading': opening.value },
    ])
  }, [
    (opening.value)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(Spinner, { size: 20 })
          ]),
          _cache[0] || (_cache[0] = _createElementVNode("p", { class: "installation-credential__text" }, "Carregando...", -1))
        ], 64))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          (_unref(lgAndUp))
            ? (_openBlock(), _createBlock(BulletStatusTag, {
                key: 0,
                definedText: "Credenciais pendentes",
                status: "reproved"
              }))
            : _createCommentVNode("", true),
          _createElementVNode("p", _hoisted_2, [
            _cache[1] || (_cache[1] = _createElementVNode("b", null, "Atenção!", -1)),
            _createTextVNode(" " + _toDisplayString(isCredentialsUpdate.value ? "Atualize" : "Adicione") + " as credenciais de acesso à concessionária para receber todos os serviços da Metha. ", 1)
          ]),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_unref(MeButton), {
              block: "",
              onClick: openCredentials
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(isCredentialsUpdate.value ? "Atualizar" : "Adicionar") + " credenciais ", 1)
              ]),
              _: 1
            })
          ])
        ], 64))
  ], 2))
}
}

})