import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "adc__invoices__section my-account" }
const _hoisted_2 = { class: "my-account__info" }
const _hoisted_3 = { class: "my-account__info__icon" }
const _hoisted_4 = { class: "my-account__info__name" }
const _hoisted_5 = { class: "my-account__info__cpf" }
const _hoisted_6 = { class: "my-account__form__content" }
const _hoisted_7 = { class: "my-account__form__content__actionArrow" }
const _hoisted_8 = { class: "my-account__form__content" }
const _hoisted_9 = { class: "my-account__form__content__actionArrow" }

import { computed, onBeforeMount, ref } from "vue";
import {
  MeIcon,
  MeTextField,
  MeSelect,
  MeButton,
} from "@/libs/metha-components";
import { Form } from "vee-validate";

import { useUserStore } from "@/stores";
import { formatCPF, formatDate } from "@/utils/format";
import getDateFromBirthdate from "@/utils/getDateFromFormattedString";
import { clearObjectEmptyFields } from "@/utils/clearObjectEmptyFields";
import { UpdateUserObject } from "@/types/UserTypes";
import ContactUpdateModal from "@/components/molecules/ContactUpdateModal.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'MyAccountPage',
  setup(__props) {

const userStore = useUserStore();

const email = computed(() => {
  return userStore.email;
});
const phone = computed(() => {
  return userStore.phone;
});
const birthdate = ref();
const rg = ref(userStore.rg);
const occupation = ref(userStore.occupation);
const maritalStatus = ref(userStore.maritalStatus);
const showContactUpdateModal = ref(false);
const typeContactUpdateModal = ref("");
const titleContactUpdateModal = ref("");
const textContactUpdateModal = ref("");

function hasAttributedValue(field: string) {
  return typeof field === "string"
    ? field?.trim().length > 0 || false
    : !!field;
}

async function updateUser() {
  let payload = {
    birthdate: getDateFromBirthdate(birthdate.value),
    rg: rg.value,
    occupation: occupation.value,
    civil_status: maritalStatus.value,
  } as UpdateUserObject;

  payload = clearObjectEmptyFields(payload);

  await userStore.updateUser({ ...payload });
}

function setContactUpdateModal(type: string) {
  typeContactUpdateModal.value = type;
  showContactUpdateModal.value = true;
}

function closeModal() {
  showContactUpdateModal.value = false;
  titleContactUpdateModal.value = "";
  textContactUpdateModal.value = "";
}

onBeforeMount(async () => {
  userStore.setPageTitle("Minha Conta");
  birthdate.value = formatDate(userStore.birthdate);
});

return (_ctx: any,_cache: any) => {
  const _directive_maska = _resolveDirective("maska")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    (showContactUpdateModal.value)
      ? (_openBlock(), _createBlock(ContactUpdateModal, {
          key: 0,
          type: typeContactUpdateModal.value,
          closeButtonText: "Continuar",
          closeModal: closeModal
        }, null, 8, ["type"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_unref(MeIcon), null, {
          default: _withCtx(() => _cache[8] || (_cache[8] = [
            _createTextVNode("nm-profile")
          ])),
          _: 1
        })
      ]),
      _createElementVNode("h2", _hoisted_4, _toDisplayString(_unref(userStore).name), 1),
      _createElementVNode("p", _hoisted_5, "CPF: " + _toDisplayString(_unref(formatCPF)(_unref(userStore).cpf)), 1)
    ]),
    _createVNode(_unref(Form), { class: "my-account__form" }, {
      default: _withCtx(() => [
        _cache[13] || (_cache[13] = _createElementVNode("h3", { class: "my-account__form__title" }, "Informações de contato", -1)),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", {
            class: "my-account__form__content__siding",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (setContactUpdateModal('email')))
          }),
          _createVNode(_unref(MeTextField), {
            label: "E-mail",
            name: "email",
            modelValue: email.value,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((email).value = $event)),
            rules: "email|required"
          }, null, 8, ["modelValue"]),
          _cache[10] || (_cache[10] = _createElementVNode("div", { class: "my-account__form__content__action" }, "Alterar E-mail", -1)),
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_unref(MeIcon), null, {
              default: _withCtx(() => _cache[9] || (_cache[9] = [
                _createTextVNode("nm-arrow-right")
              ])),
              _: 1
            })
          ])
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("div", {
            class: "my-account__form__content__siding",
            onClick: _cache[2] || (_cache[2] = ($event: any) => (setContactUpdateModal('phone')))
          }),
          _withDirectives(_createVNode(_unref(MeTextField), {
            label: "Celular",
            name: "phone",
            modelValue: phone.value,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((phone).value = $event)),
            rules: "required|phone"
          }, null, 8, ["modelValue"]), [
            [_directive_maska, '(##) #####-####']
          ]),
          _cache[12] || (_cache[12] = _createElementVNode("div", { class: "my-account__form__content__action" }, "Alterar Celular", -1)),
          _createElementVNode("div", _hoisted_9, [
            _createVNode(_unref(MeIcon), null, {
              default: _withCtx(() => _cache[11] || (_cache[11] = [
                _createTextVNode("nm-arrow-right")
              ])),
              _: 1
            })
          ])
        ])
      ]),
      _: 1
    }),
    _createVNode(_unref(Form), {
      onSubmit: updateUser,
      class: "my-account__form"
    }, {
      default: _withCtx(({ errors, isSubmitting }) => [
        _cache[15] || (_cache[15] = _createElementVNode("h3", { class: "my-account__form__title" }, "Informações pessoais", -1)),
        _withDirectives(_createVNode(_unref(MeTextField), {
          label: "Data Nascimento",
          name: "birthdate",
          modelValue: birthdate.value,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((birthdate).value = $event)),
          rules: `${
          hasAttributedValue(_unref(userStore).birthdate)
            ? 'required|birthdate|isAdult'
            : ''
        }`
        }, null, 8, ["modelValue", "rules"]), [
          [_directive_maska, '##/##/####']
        ]),
        _createVNode(_unref(MeTextField), {
          label: "RG/Documento",
          name: "rg",
          modelValue: rg.value,
          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((rg).value = $event)),
          rules: { max: 15, required: hasAttributedValue(_unref(userStore).rg) }
        }, null, 8, ["modelValue", "rules"]),
        _createVNode(_unref(MeTextField), {
          label: "Profissão",
          name: "occupation",
          modelValue: occupation.value,
          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((occupation).value = $event)),
          rules: { min: 3, required: hasAttributedValue(_unref(userStore).occupation) }
        }, null, 8, ["modelValue", "rules"]),
        _createVNode(_unref(MeSelect), {
          label: "Estado Civil",
          name: "maritalStatus",
          modelValue: maritalStatus.value,
          "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((maritalStatus).value = $event)),
          rules: { required: hasAttributedValue(_unref(userStore).maritalStatus) },
          items: [
          'Solteiro(a)',
          'Casado(a)',
          'Separado(a)',
          'Divorciado(a)',
          'Viúvo(a)',
        ],
          class: "my-account__form__select"
        }, null, 8, ["modelValue", "rules"]),
        _createVNode(_unref(MeButton), {
          type: "submit",
          class: "my-account__form__button",
          size: "large",
          disabled: !!Object.keys(errors).length || isSubmitting,
          block: ""
        }, {
          default: _withCtx(() => _cache[14] || (_cache[14] = [
            _createTextVNode(" Salvar alterações ")
          ])),
          _: 2
        }, 1032, ["disabled"])
      ]),
      _: 1
    })
  ]))
}
}

})